<template>
  <v-apex-chart
    type="bar"
    :series="chart.series"
    :options="chart.options"
    min-height="300px"
    label="Total payments by year"
    @update="getStat(true)"
    :loading="loading"
    :time="time"
  ></v-apex-chart>
</template>

<script>
import { doubleRound, moneyFormat } from "@/functions";
import Chart from "@/mixins/Chart";
export default {
  name: "payments_per_year",
  mixins: [Chart],
  data: () => ({
    config: {
      storageGet: "getPayTenData",
      axiosLink: "getPayTen",
      storageSet: "setPayTenData"
    }
  }),
  computed: {
    chart() {
      try {
        let arr = this.stat_data;
        let options = {},
          series = [],
          categories = [],
          data = [];
        let today = new Date();
        today.setFullYear(today.getFullYear() - 9);
        for (let i = today.getFullYear(); i <= new Date().getFullYear(); i++) {
          categories.push(i);
          if (typeof arr[i] !== typeof undefined) {
            data.push(Math.round(arr[i]));
          } else {
            data.push(0);
          }
        }

        options = {
          chart: {
            type: "bar"
          },
          plotOptions: {
            bar: {
              dataLabels: {
                position: "bottom"
              },
              columnWidth: Math.round((categories.length / 20) * 100) + "%"
            }
          },
          dataLabels: {
            enabled: true,

            offsetY: 10,
            style: {
              fontSize: "12px",
              colors: [this.$vuetify.theme.currentTheme.primary]
            },
            formatter: val => {
              return doubleRound(val);
            }
          },

          xaxis: {
            categories,
            position: "bottom",
            axisBorder: {
              show: false
            },
            axisTicks: {
              show: false
            },
            crosshairs: {
              fill: {
                type: "gradient",
                gradient: {
                  colorFrom: "#D8E3F0",
                  colorTo: "#BED1E6",
                  stops: [0, 100],
                  opacityFrom: 0.4,
                  opacityTo: 0.5
                }
              }
            },
            tooltip: {
              enabled: true
            }
          },
          yaxis: {
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true
            },
            labels: {
              formatter: val => {
                return moneyFormat(val);
              }
            }
          },
          tooltip: {
            y: {
              formatter: value => {
                return moneyFormat(value);
              }
            }
          }
        };
        series = [
          {
            name: "Payment",
            data
          }
        ];
        return { options, series };
      } catch (e) {
        return {};
      }
    }
  }
};
</script>

<style scoped></style>
